<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoader" />
  <div v-else>
    <div class="flex justify-start items-end">
      <div>
        <HeaderGlobal
          titlemenu1="Friends’ Pets"
          :to1="'/petoverview/new'"
          :titlemenu2="$t('pet_dropdown_menu_public_profile')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-5/6 font-proximaMedium relative">
        <div class="absolute">
          <img
            class="h-6/6 w-6/6"
            src="@/assets/images/cadre.svg"
            alt="dialog-box"
          />
        </div>
        <div
          class="pt-5 md:pt-14 w-full flex items-start justify-start relative z-10"
        >
          <div
            class="lg:mx-14 md:mx-12 mx-8 relative cursor-pointer"
            @click="profilePictureModals = true"
          >
            <img
              class="mt-6 border border-primary rounded-full"
              style="width: 102px; height: 102px"
              :src="picture"
              alt="pet_icon"
            />
          </div>
          <ul
            class="lg:w-4/12 md:w-5/12 text-left text-sm md:text-base font-proximaLight list-disc"
          >
            <li class="my-2 text-primary">
              <span class="text-black font-bold">{{
                biometricData.callName
              }}</span>
            </li>
            <li v-if="ownerData" class="my-2 text-primary">
              <span class="text-black font-bold">{{
                ownerData.firstName + " " + ownerData.lastName
              }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold"> {{ biometricData.age }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold">{{
                biometricData.breeding_sex
              }}</span>
            </li>
            <li class="my-2 text-primary" v-if="biometricData.height_weight">
              <span class="text-black font-bold"
                >{{ biometricData.height_weight }}
              </span>
            </li>
            <!--  <li class="my-2 text-primary">
                <span class="text-black font-bold"> </span>
              </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div
      class="mt-16 md:mt-32 flex flex-col md:flex-row items-center md:items-end"
    >
      <img class="w-44" src="@/assets/images/fluff_1.svg" alt="standing-logo" />
      <div>
        <div
          class="mt-3 w-full text-primary px-12 py-1 border border-primary rounded-md flex items-center"
          @click="openConnectRequestModals"
        >
          <img src="@/assets/images/wifi.png" alt="standing-logo" />
          <span>I’d like to connect.</span>
        </div>
        <div
          class="mt-2 w-full text-primary bg-white px-16 py-4 border border-primary rounded-md"
          @click="openReadMoreModals"
        >
          Read More About Breed
        </div>
      </div>
    </div>

    <!-- profile picture -->
    <ModalsConfirm :show="profilePictureModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="profilePictureModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <vue-image-slider
            class="mx-auto hidden md:block"
            :images="imageArray"
            :intervalVal="3000"
            :height="300"
            :width="400"
          />
          <vue-image-slider
            class="mx-auto block md:hidden"
            :images="imageArray"
            :intervalVal="3000"
            :height="160"
            :width="250"
          />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- profile picture end -->
    <!-- I’d like to connect. -->
    <ModalsConfirm class="w-5/12" :show="connectRequestModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeContactRequest"
        >
          ✖
        </div>
        <h1 class="text-2xl">Contact Request</h1>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <FormError :errors="errorForm" />
        <h2 class="text-left font-proximaLight text-xl mb-5">
          {{ $t("add_not_modals_title") }}
        </h2>
        <div>
          <textarea
            class="w-full p-2 border-2 border-primary rounded-md text-lg"
            cols="30"
            rows="5"
            v-model="note"
            placeholder="Add a note to your request to introduce yourself and explain why you want to connect."
          ></textarea>
        </div>
      </div>
      <BorderedButton
        slot="leftBtn"
        class="md:mr-1"
        :title="$t('global_cancel')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full"
        @click.native="closeContactRequest"
      />

      <SubmitButton
        slot="rightBtn"
        class="md:ml-1"
        title="Confirm"
        background="bg-success"
        paddingX="px-8"
        width="w-full"
        @click.native="submitConnectRequest"
      />
    </ModalsConfirm>
    <!-- read more pdf. -->
    <ModalsConfirm class="w-5/12" :show="readMoreModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="readMoreModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <iframe
          src="http://www.fci.be/Nomenclature/Standards/001g07-en.pdf"
          style="width: 100%; height: 300px; border: none"
        >
          Oops! an error has occurred.
        </iframe>
      </div>
    </ModalsConfirm>
  </div>
</template>
  
  <script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import FormError from "../../warning/FormError.vue";

import VueImageSlider from "vue-image-slider";
import StarRating from "vue-star-rating";
import Loader from "../../loader/Loader.vue";
import {
  petBreedPdfLink,
  getGallery,
} from "../../../services/petRecordService";
import { pictureBySpecies } from "../../../methods/petMethods";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    ModalsConfirm,
    VueImageSlider,
    StarRating,
    BorderedButton,
    SubmitButton,
    FormError,
    Loader,
  },

  data() {
    return {
      showLoader: true,
      profilePictureModals: false,
      imageArray: [
        "https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/02/17/23/03/usa-1206240_1280.jpg",
        "https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/12/04/19/30/berlin-cathedral-1882397_1280.jpg",
      ],
      petData: {},
      picture: null,
      owner: {},
      note: "",
      readMoreModals: false,
      breedPdf: "http://www.fci.be/en/nomenclature/ENGLISH-POINTER-1.html",
    };
  },

  computed: {
    ...mapGetters({
      petProfileData: "petRecord/getPetProfile",
    }),
    biometricData() {
      if (this.petProfileData) {
        return this.petProfileData["Biometric Data"];
      }
    },
    ownerData() {
      if (this.petProfileData) return this.petProfileData["owner"];
    },
    noteData() {
      return this.petProfileData.care_blog
        ? this.petProfileData.care_blog.own_entries
        : [];
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
    connectRequestModals() {
      return this.$store.state.addNonContactRequestState;
    },
  },

  async created() {
    var retrievedObject = localStorage.getItem("pet");
    this.petData = JSON.parse(retrievedObject);

    /*   const { data: petGallery } = await getGallery(this.petData.petID);
    petGallery.map((item) => this.imageArray.push(item.petImage));
    this.picture = this.petData.profile_picture
      ? this.petData.profile_picture
      : pictureBySpecies(this.petData); */

    this.petData ? this.getPetProfile(this.petData.petID) : "";
  },

  watch: {
    biometricData: function () {
      this.showLoader = false;
    },
  },
  methods: {
    ...mapActions({
      getPetProfile: "petRecord/getPetProfile",
      contactRequest: "contact/contactRequest",
    }),

    submitConnectRequest() {
      const bodyData = {
        userEmailAddress: localStorage.getItem("email"),
        contactEmailAddress: this.petProfileData["owner"].emailAddress,
        notes: this.note,
        status: 1,
      };
      this.contactRequest(bodyData);
    },
    openConnectRequestModals() {
      this.$store.commit("addNonContactRequestModals", true);
    },
    closeContactRequest() {
      this.note = "";
      this.$store.commit("errorForm", "");
      this.$store.commit("addNonContactRequestModals", false);
    },
    async openReadMoreModals() {
      const { data: response } = await petBreedPdfLink(1);
      console.log(response);
      window.open(response.BreedPdf, "_blank");
    },
  },
};
</script>
  
  <style scoped></style>
  