<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col lg:flex-row items-start">
    <SectionLeft class="hidden lg:block" />
    <div class="h-full md:h-screen w-full lg:w-6/12 px-4 lg:pr-32">
      <!-- in pet overview / friends pet -->
      <ContactSection
        v-if="routePath == 'contactview'"
        :picture="picture"
        :pet="petData"
      />
      <ContactspSection
        v-if="routeNonContact == 'publicPetSharingSP'"
        :picture="picture"
        :pet="petData"
      />
      <!-- if pet.private then PrivateAccountSettingSection else ContactSection -->
      <PrivateAccountSettingSection
        v-if="routePath == 'privateaccountsettingview'"
        :picture="picture"
        :pet="petData"
      />
      <!-- service provider / passive sp with authantification => user -->
      <ServiceSection
        v-if="routePath == 'serviceproviderview'"
        :picture="picture"
        :pet="this.petData"
      />
      <!-- publicByUrlQR link / public pet profile non user -->
      <NonContactSection
        v-if="routeNonContact == 'PublicProfileNonContact'"
        :privat="this.privat"
      />

      <UnregisteredSection
        v-if="routePath == 'unregisteredpetview'"
        :picture="picture"
        :pet="this.$route.params.data"
      />
      <img
        class="h-28 mt-8 md:mt-0 mb-4 md:mb-8 lg:hidden m-auto"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import ContactSection from "../components/publicprofile/contactview/ContactSection.vue";
import ContactspSection from "../components/publicprofile/contactview/ContactspSection.vue";
import ServiceSection from "../components/publicprofile/serviceproviderview/ServiceSection.vue";
import NonContactSection from "../components/publicprofile/noncontactindividualuser/NonContactSection.vue";
import PrivateAccountSettingSection from "../components/publicprofile/noncontactindividualuser/PrivateAccountSettingSection.vue";
import UnregisteredSection from "../components/publicprofile/unregisteredpetview/UnregisteredSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";
import { pictureBySpecies } from "../methods/petMethods";
import { mapActions, mapGetters } from "vuex";
import {
  getPetProfileApi,
  petBreedPdfLink,
} from "../services/petRecordService";
import { publicPetProfileSharedToSP } from "../services/sharingService";

export default {
  components: {
    SectionLeft,
    ContactSection,
    ContactspSection,
    ServiceSection,
    NonContactSection,
    PrivateAccountSettingSection,
    UnregisteredSection,
    DataserviceLogo,
  },
  data() {
    return {
      routePath: "",
      routeNonContact: "",
      picture: null,
      petData: {},
      privat: false,
    };
  },
  async created() {
    console.log("88888888888888");
    const path = this.$router.currentRoute.params.view;
    this.routePath = path;
    this.routeNonContact = this.$router.currentRoute.name;
    //PublicProfileNonContact
    console.log(path);
    if (this.$route.params["codePS"]) {
      console.log("/////");
      this.routePath == "publicPetSharingSP";
      console.log(this.routePath, this.routeNonContact);
      await publicPetProfileSharedToSP(this.$route.params["codePS"]).then(
        (response) => {
          console.log(response.data);
          this.petData = response.data;
          /* this.petData["biometric_data"].age.includes("***")
            ? (this.privat = true)
            : (this.privat = false); */

          // this.getEmergencyContacts(localStorage.getItem("email"));
        }
      );
    }

    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    const petData = JSON.parse(retrievedObject);
    this.petData = petData;
    console.log("petData", petData);

    try {
      this.picture = petData.profile_picture
        ? petData.profile_picture
        : pictureBySpecies(petData); //profile_picture
    } catch (error) {
      this.picture = null;
    }
    petData ? this.getPetProfile(petData.petID) : "";

    if (this.$route.params["code10"]) {
      await getPetProfileApi(this.$route.params["code10"]).then((response) => {
        console.log(response.data);
        this.petData = response.data;
        this.petData["Biometric Data"].age.includes("***")
          ? (this.privat = true)
          : (this.privat = false);

        // this.getEmergencyContacts(localStorage.getItem("email"));
      });
    }
  },
  methods: {
    ...mapActions({
      getPetProfile: "petRecord/getPetProfile",
    }),
  },
};
</script>
